<template>
  <div class="search">
    <Search @search="search" :setVal="setVal" class="search" ref="search" :searValue="searValue" />
    <SearchIndex :setVal="setVal" />
  </div>
</template>
<script>
import Search from '@/components/Search';
import SearchIndex from '../searchComp/index';
import { Toast } from 'vant';
// import VideoIndex from '../video/index';
export default {
  name: 'search',

  components: {
    Search,
    SearchIndex,
    // VideoIndex
  },
  data() {
    return {
      searValue: '',
    };
  },
  methods: {
    setVal(e){
      console.log(e,'====e')
    },
    setHistory(name) {
      let itemInfo = {
        name
      }
      var localStorageTag = [];
      let hasLocalStorageTag = JSON.parse(JSON.stringify(localStorage.getItem('localStorageTag')));
      let hasTag = '';
      if (hasLocalStorageTag && hasLocalStorageTag.length > 0) {
        hasTag = hasLocalStorageTag.indexOf(itemInfo.name)>0;
      }
      localStorageTag = hasLocalStorageTag&&JSON.parse(hasLocalStorageTag) ||[];
      if (!hasTag) {
        localStorageTag.push(itemInfo.name);
      }
      localStorage.setItem('localStorageTag', JSON.stringify(localStorageTag));
    },
    search(e) {
      if(!e){
        Toast('请输入搜索关键字');
        return;
      }
      this.searValue = e;
      this.setHistory(e);
      this.$router.push('/search/detail?keywords='+e)
    },
  },
};
</script>
<style lang="scss" scoped>
.search {
}
</style>
