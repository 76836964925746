<template>
  <div class="search-page">
    <div class="publishList overflow-y-auto" ref="publishList">
      <search-list :src="require('./img/fire.png')" title="大家在搜" :setVal="setVal" :search-data="searchData" />
      <search-list
        :isHistory="true"
        v-if="historyData && historyData.length > 0"
        :src="require('./img/history.png')"
        title="历史搜索"
        :search-data="historyData"
      >
        <img slot="titleRight" @click="clearHistory" src="./img/delete.png" alt="" />
      </search-list>
      <van-sticky @change="changeSticky" offset-top="7.573rem"></van-sticky>
      <p class="search-people-watch bottom-gradient">大家都在看</p>
      <div>
        <PullRefresh
          :loading="loading"
          :refreshing="refreshing"
          :finished="finished"
          @onLoad="onLoad"
          @onRefresh="onRefresh"
          :isHigehtMax="true"
          :isNoData="isNoData"
          :error="error"
          :disabled="disableRefresh"
        >
          <div class="listBox" v-for="item in list" :key="item.sectionID">
            <div class="titleBox flex-center-between">
              <div class="title">{{ item.sectionName }}</div>
              <div class="moreIcon" @click="jumpMore(item)">
                <svg-icon class="moreIcon" iconClass="moreIcon" />
              </div>
            </div>
            <div class="threeGrid">
              <VideoBox
                class="videoBox"
                @click.native="openVideo(item)"
                :videoInfo="item"
                v-for="item in item.videoInfo.slice(0, 3)"
                :key="item.id"
              />
            </div>
          </div>
        </PullRefresh>
      </div>
    </div>
  </div>
</template>

<script>
import SearchList from './searchList.vue';
import PullRefresh from '@/components/PullRefresh';
import { Sticky } from 'vant';
import { queryTabList } from '@/api/home';
import { searchHotTag, searchIndex } from '@/api/home';
import { queryModulsList } from '@/api/movies';
import VideoBox from '@/components/VideoBox';

export default {
  name: 'QRCode',
  components: { SearchList, PullRefresh, VideoBox, [Sticky.name]: Sticky },
  data() {
    return {
      searchData: [],
      historyData: [],
      list: [],
      tabList: [],
      tab: {},
      pageNumber: 1,
      pageSize: 10, // 条数
      isNoData: false,
      loading: true,
      refreshing: false,
      finished: false,
      error: false,
      disableRefresh: false,
      scrollCount: 0,
    };
  },
  computed: {},
  mounted() {
    this.getTagList();
    // this.searchIndexList();
    this.getTabList();
    let data =  JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem('localStorageTag')))) || [];
    this.historyData = data.reverse();
  },
  created() {},
  methods: {
    changeSticky() {
      this.disableRefresh = !this.disableRefresh;
      // console.log("====================")
    },
    async getTabList() {
      let res = await this.$Api(queryTabList, null);
      if (res.code == 200) {
        this.tabList = res.data;
        this.tab = this.tabList.find((item) => item.subModuleName == '推荐');
        this.getList();
      }
    },
    clearHistory() {
      this.historyData = [];
      localStorage.removeItem('localStorageTag');
    },
    // 上拉加载
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.getList();
    },
    // 下拉刷新
    onRefresh() {
      // this.$nextTick(()=>{
      //   this.$refs.publishList.scrollTop = 0;
      // })
      // this.scrollCount++;
      // if (this.scrollCount >= 2) {
      //   this.$nextTick(() => {
      //     this.$refs.publishList.scrollTop = 0;
      //     this.scrollCount = 0;
      //   });
      // }
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.getList('refresh');
    },
    setVal(e) {
      console.log(e, 'val====');
      this.$emit('setVal', e);
    },
    async getList(type) {
      let req = {
        id: this.tab.id,
        data: {
          pageSize: String(this.pageSize),
          sectionSize: String(this.pageSize),
          sectionPage: String(this.pageNumber),
        },
      };
      try {
        let res = await this.$Api(queryModulsList, req);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.sections || [];
          if (type === 'refresh') {
            this.list = list;
          } else {
            this.list = this.list.concat(list);
          }
          if (this.list.length <= 0) {
            this.isNoData = true;
          }
          if (!res.data.list) {
            this.finished = true;
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.loading = false;
        this.error = true;
      }
    },
    openVideo(item) {
      if (item.playTime > 300) {
        this.$router.push({
          path: '/horizontalVideo',
          query: {
            id: item.id,
            sectionId: item.watch.isFreeWatch ? this.$route.query.id : '',
          },
        });
      } else {
        this.$store.commit('video/DELETE_JUMPVIDEOLIST');
        this.$store.commit('video/SET_JUMPVIDEOLIST', {
          list: this.list,
          videoInfo: item,
        });
        this.$router.push('/shortVideoDetails');
      }
    },
    async searchIndexList() {
      let res = await this.$Api(searchIndex, null);
      // this.watchData.list = res.data.hVidList;
      console.log(res.data.hVidList, 'res====');
      // if (res.code == 200) {
      //   this.searchData = res.data.list;
      // }
    },
    async getTagList() {
      let res = await this.$Api(searchHotTag, null);
      if (res.code == 200) {
        this.searchData = res.data.list;
      }
    },
    jumpMore(item) {
      this.$router.push({
        path: '/topicMore',
        query: {
          topicId: item.sectionID,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .videoBox .coverBox {
  height: 159px;
}
$gradient: linear-gradient(-90deg, #faa982, #f74a82);
$width: 90%;
.search-page {
  width: $width;
  margin: 20px auto;
}
.search-people-watch {
  font-size: 14px;
  line-height: 20px;
  color: #000;
  font-weight: 600;
  // height: 284px;
}
.listBox {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 10px;
  .titleBox {
    font-size: 16px;
    color: rgb(121, 121, 121);
    font-weight: 600;
    margin: 10px 0;
    box-sizing: border-box;
    .moreIcon {
      height: 18px;
      width: 30px;
    }
  }
  .threeGrid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .videoBox {
      width: 106px;
    }
  }
}
.publishList {
  height: calc(100vh - 64px);
  // margin-top: 4px;
}
.bottom-gradient {
  position: relative;
  &::after {
    content: '';
    display: block;
    height: 8px;
    width: 25px;
    background-image: $gradient;
    border-radius: 5px;
  }
}
</style>
