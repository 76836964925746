<template>
  <div class="search-list-content">
    <div class="search-list-title">
      <div class="search-list-title-left">
        <img class="search-list-title-img" :src="src" alt="" />
        <span class="search-list-title-text">{{ title }}</span>
      </div>
      <slot name="titleRight" />
    </div>
    <ul class="search-list">
      
      <li v-show="index<=7" v-for="(item, index) in searchData" :key="`searchList-${index}`" class="search-list-text" @click="setHistory(item)">
        {{ isHistory?item:item.name }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SearchList',
  components: {},
  props: {
    isHistory:{
      type: Boolean,
      default: false,
    },
    setVal:{
      type: Function,
      default: null,
    },
    src: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    searchData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {
    setHistory(itemInfo) {
      let name = this.isHistory?itemInfo:itemInfo.name;
      this.$emit("setVal",name)
      this.$router.push('/search/detail?keywords='+name)
      if(this.isHistory){
        return;
      }
      var localStorageTag = [];
      let hasLocalStorageTag = JSON.parse(JSON.stringify(localStorage.getItem('localStorageTag')));
      let hasTag = '';
      if (hasLocalStorageTag && hasLocalStorageTag.length > 0) {
        hasTag = hasLocalStorageTag.indexOf(itemInfo.name)>0;
      }
      localStorageTag = hasLocalStorageTag&&JSON.parse(hasLocalStorageTag) ||[];
      if (!hasTag) {
        localStorageTag.push(itemInfo.name);
      }
      localStorage.setItem('localStorageTag', JSON.stringify(localStorageTag));
    },
  },
};
</script>

<style lang="scss" scoped>
.search-list-content {
  margin-bottom: 20px;
}
.search-list {
  display: flex;
  flex-wrap: wrap;
  justify-items: center;
  //   justify-content: space-between;
}
.search-list-title,
.search-list-title-left {
  display: flex;
  align-content: center;
  align-items: center;
}
.search-list-title {
  justify-content: space-between;
  justify-items: center;
}
.search-list-title-text {
  font-size: 14px;
  color: rgba(121, 121, 121, 1);
  margin-left: 5px;
}
.search-list-text {
  width: 74px;
  height: 30px;
  line-height: 30px;
  border-radius: 10px;
  text-align: center;
  font-size: 12px;
  color: rgba(121, 121, 121, 1);
  margin-top: 20px;
  margin-right: 13px;
  background-image: linear-gradient(180deg, rgba(255, 237, 243, 1), rgba(253, 249, 246, 1));
  &:nth-child(4n) {
    margin-right: 0;
  }
}
</style>
